<template>
  <div id="benesApp" class="v-application">
    <div class="app-container app-theme-white">
      <transition name="fade" mode="out-in" appear>
        <!-- 상단 프레임 -->
        <Header />
      </transition>
      <transition name="fade" mode="out-in" appear>
        <!-- 왼쪽 메뉴 영역 (홈, 햄버거까지 포함하고 있음) -->
        <Sidebar />
      </transition>
      <div class="app-main__outer">
        <!-- 각 메뉴의 페이지들이 표시되는 영역 -->
        <div class="app-main__inner p-3">
          <!-- slot tag에 name이 없을 경우 하위 자식이 slot연결하지 않아도 연결됨 -->
          <slot></slot>
        </div>
        <transition name="fade" mode="out-in" appear>
          <!-- 최하단에 표시되는 정보 -->
          <Footer />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import Footer from "../Components/Footer";

export default {
  name: "app",
  components: {
    Header,
    Sidebar,
    Footer,
  },
  methods: {
  },

  data() {
    return {
    };
  }
};
</script>
<style>
/* TODO : 팝업창의 z-index가 2001이어서 강제로 z-index를 높게 지정, element-ui의 popover도 z-index가 2021임 */
.mx-datepicker-popup {
  z-index: 10000 !important;
}
</style>
import Vue from 'vue'
import Router from 'vue-router'
// import { LFeatureGroup } from 'vue2-leaflet';

Vue.use(Router);
// import sampleRouter from './sample/sampleRouter.js'

export const asyncRouterMap = [
  /** When your routing table is too long, you can split it into small modules**/
  {
    path: '/404',
    name: '404',
    hidden: true,
    meta: { layout: 'userpages' },
    component: () => import('@/pages/error/404.vue'),
  },
  {
    path: '/login',
    name: 'login',
    hidden: true,
    meta: { layout: 'userpages' },
    component: () => import('@/pages/login/Login.vue'),
  },
  // {
  //   path: '',
  //   manual: false,
  //   name: 'DashBoard',
  //   title: 'DashBoard',
  //   child: [
  //   ]
  // },
  {
    path: '/',
    name: 'main',
    title: '메인화면',
    meta: { layout: 'default', editable: true, menulog: 'N' },
    component: () => import('@/pages/main/index.vue'),
    hidden: true,
  },
  {
    path: '/sso',
    name: 'sso',
    meta: { layout: 'userpages', menulog: 'N' },
    component: () => import('@/pages/login/sso.vue'),
    hidden: true,
  },
  {
    path: '/ram/compare/compareScenario',
    name: 'compareScenario',
    hidden: true,
    meta: { layout: 'userpages', menulog: 'N' },
    component: () => import('@/pages/ram/compare/compareScenario.vue'),
  },
  // {
  //   path: '/',
  //   name: 'mainRouter',
  //   hidden: true,
  //   meta: { layout: 'default', menulog: 'N' },
  //   component: () => import('@/pages/main/mainRouter.vue'),
  // },
  {
    path: '/sys/menu/manualPage',
    name: 'manual',
    hidden: true,
    title: '매뉴얼',
    meta: { layout: 'userpages', menulog: 'N' },
    component: () => import('@/pages/sys/menu/manualPage.vue'),
  },
  {
    path: '/sys/defect/defectManage',
    name: 'defect',
    hidden: true,
    title: '결함등록',
    meta: { layout: 'userpages', menulog: 'N' },
    component: () => import('@/pages/sys/defect/defectManage.vue'),
  },
  // {
  //   path: '/safeWorkPermit',
  //   name: 'workPermit',
  //   title: '작업허가서',
  //   meta: { layout: 'default', editable: true, manual: true, title: '작업허가서', },
  //   component: () => import('@/pages/sop/swp/safeWorkPermit.vue'),
  // },
  // {
  //   path: '/4M',
  //   name: '4M',
  //   title: '위험성평가',
  //   meta: { layout: 'default', editable: true, manual: true, title: '위험성평가', params: { ramTechniqueCd: 'RT00000025' } },
  //   component: () => import('@/pages/ram/riskList.vue'),
  // },
  // {
  //   path: '/impr',
  //   name: 'impr',
  //   title: '개선관리',
  //   meta: { layout: 'default', editable: true, manual: true, title: '개선관리', },
  //   component: () => import('@/pages/sop/ibm/impr.vue'),
  // },
  // {
  //   path: '/vendorCouncil',
  //   name: 'vendorCouncil',
  //   title: '안전보건 협의체',
  //   meta: { layout: 'default', editable: true, manual: true, title: '안전보건 협의체', },
  //   component: () => import('@/pages/sop/csm/vendorCouncil.vue'),
  // },
  // {
  //   path: '/consolidation',
  //   name: 'consolidation',
  //   title: '합동점검',
  //   meta: { layout: 'default', editable: true, manual: true, title: '합동점검', },
  //   component: () => import('@/pages/sop/check/consolidation/consolidationCheck.vue'),
  // },
  // {
  //   path: '/imprVendorRequest',
  //   name: 'imprVendorRequest',
  //   title: '기술지도업체 개선요청',
  //   checkVendor: true,
  //   meta: { layout: 'default', editable: true, title: '기술지도업체 개선요청', },
  //   component: () => import('@/pages/sop/ibm/imprVendorRequest.vue'),
  // },
  // {
  //   path: '/notice',
  //   name: 'notice',
  //   title: '공지사항',
  //   meta: { layout: 'default', editable: true, title: '공지사항', },
  //   component: () => import('@/pages/sys/notice/noticeManage.vue'),
  // },
  // {
  //   path: '/myPage',
  //   name: 'myPage',
  //   title: 'My Page',
  //   meta: { layout: 'default', editable: true, title: 'My Page', },
  //   component: () => import('@/pages/my/myPage.vue'),
  // },
];
/**
 * default route 정보 관리
 *
 * 그 외에 sample route 정보 관리
 * sample은 언제든지 쳐낼수 있도록 소스가 관리되어야 함
 */
// asyncRouterMap = asyncRouterMap.concat(sampleRouter);


export default new Router({
    scrollBehavior() {
        return window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    routes: asyncRouterMap,
    mode: 'history', // # 제거
})

/**
 * 수정용 협력사 전용 config
 */
let transactionPartnerConfig = {
  sso: {
    insert: {
      url: '/api/auth/partner/sso'
    }
  },
  auth: {
    login: {
      insert: {
        url: '/api/auth/partner/login',
      },
      logout: {
        url: '/api/auth/partner/logout',
      },
      update: {
        url: '/api/auth/partner/logout/{0}',
      },
      initPwdChange: {
        url: '/api/auth/partner/changepw',
      },
      nextChangePw: {
        url: '/api/auth/partner/nextChangePw',
      },
    },
  },
  mdm: {
    cim: {
      vendor: {
        chgId: {
          url: "/api/mdm/cim/vendor/chgId"
        },
        pwdChange: {
          url: "/api/mdm/cim/vendorpwd/change"
        },
      }
    }
  },
  sop: {
    swp: {
      workPermit: {
        request: {
          url: "/api/sop/swp/workPermit/request"
        },
        reject: {
          url: "/api/sop/swp/workPermit/request/reject"
        },
      }
    }
  }
};

export default transactionPartnerConfig;

<template>
  <div class="header-dots" v-if="plantItems.length > 1">
    <span slot="button-content">
      <q-btn
        flat
        dense
        round
        color="white"
        class="toptabshort"
        icon="villa">
        <q-tooltip anchor="center left" self="center right">
          기본사업장 : {{defaultPlantName}}
        </q-tooltip>
        <q-menu 
          anchor="bottom left" 
          self="top middle" 
          auto-close
          transition-show="jump-down"
          transition-hide="jump-up">
          <q-list class="topMenuShort">
            <q-item clickable v-ripple v-for="tag in plantItems"
              :key="tag.code"
              :name="tag.codeName"
              :class="tag.class"
              ref="tag"
              @click="setDefaultPlantCd(tag)">
              <q-item-section>
                <q-item-label lines="1">{{tag.codeName}}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </span>
  </div>
</template>

<script>
// import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    // VuePerfectScrollbar,
  },
  data() {
    return {
      plantItems: [],
      defaultPlantName: '',
    };
  },
  // computed: {
  //   visitedViews() {
  //     return this.$store.state.tagsView.visitedViews;
  //   },
  // },
  // watch: {
  //   $route() {
  //     this.addViewTags();
  //     this.topTab = this.$route.path
  //   },
  //   topTab() {
  //     if (this.topTab !== this.$route.path) {
  //       this.topTab = this.$route.path
  //     }
  //   },
  // },
  // mounted() {
  //   this.addViewTags();
  //   this.topTab = this.$route.path
  // },
  beforeMount() {
    this.init();
  },
  methods: {
    init() {
      this.$comm.getComboItems('PLANT_CD').then(_result => {
        this.plantItems = [];
        let plantCds;
        if (this.$store.getters.user.plantCds === null || this.$store.getters.user.plantCds === '') {
          plantCds = this.$store.getters.user.plantCd.split(',');
          this.allPlant = this.$store.getters.user.plantCd;
        } else {
          plantCds = this.$store.getters.user.plantCds.split(',');
          this.allPlant = this.$store.getters.user.plantCds;
        }
        this.$_.forEach(_result, _item => {
          let index = plantCds.findIndex(a => a === _item.code);
          if (index > -1) {
            this.setClass(_item, plantCds);
            this.plantItems.push(_item);
          }
        })

        // if (this.plantItems.length === 1) {
        //   window.localStorage.setItem('defaultPlantCd', this.plantItems[0]);
        // }
      });
    },
    setDefaultPlantCd(_tag) {
      let defaultPlantCd = window.localStorage.getItem('defaultPlantCd');
      if (defaultPlantCd != _tag.code) {
        window.localStorage.setItem('defaultPlantCd', _tag.code);
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '변경된 기본사업장으로 화면을 새로고침 하시겠습니까?',
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            window.location.reload();
          },
          // 취소 callback 함수
          cancelCallback: () => {
            this.$_.forEach(this.plantItems, _item => {
              this.setClass(_item, this.plantItems);
            })
          },
        });
      }
    },
    setClass(_item, plantCds) {
      let defaultPlantCd = window.localStorage.getItem('defaultPlantCd');
      if (!defaultPlantCd === null || defaultPlantCd === 'undefined') {
        defaultPlantCd = plantCds[0];
        window.localStorage.setItem('defaultPlantCd', defaultPlantCd);
      }
      if (defaultPlantCd === _item.code) {
        this.defaultPlantName = _item.codeName
        this.$set(_item, 'class', 'activeTopMenu')
      } else {
        this.$set(_item, 'class', '')
      }

    },
    // isActive(_tag) {
    //   let defaultPlantCd = window.localStorage.getItem('defaultPlantCd');
    //   if (defaultPlantCd === null || defaultPlantCd === '') {
    //     window.localStorage.setItem('defaultPlantCd', this.$store.getters.user.plantCd);
    //     defaultPlantCd = this.$store.getters.user.plantCd;
    //   }
    //   if (defaultPlantCd === _tag.code) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    // addViewTags() {
    //   const { name } = this.$route;
    //   if (name) {
    //     this.$store.dispatch('addView', this.$route);
    //   }
    //   return false;
    // },
    // isActive(route) {
    //   return route.path === this.$route.path;
    // },
    // movePath(tag) {
    //   if (!this.isActive({path: tag.path})) {
    //     this.$comm.movePage(this.$router, tag.path)
    //   }
    // },
    // closeTag(tag) {
    //   this.$store.dispatch('delView', tag).then(({ visitedViews }) => {
    //     if (this.isActive(tag)) {
    //       const latestView = visitedViews.slice(0)[0];
    //       if (latestView) {
    //         this.$router.push(latestView);
    //       } else {
    //         this.$router.push('/');
    //       }
    //     }
    //   });
    // },
  }
};
</script>
<style lang="sass">
.topMenuShortDropdown
  padding-top: 0px !important
  padding-bottom: 0px !important
.toptabshort
  width: 26px
  height: 26px
  margin-right: 10px
  i
    margin-top: -8px
    margin-left: -6px
.topMenuShort
  min-width: 200px
  max-height: 289px
  *:hover
    text-decoration: none
  i
    font-size: 11px !important
  .q-item__label
    padding-left: 10px
</style>

<!--
  목적 : 사업장 select box
  작성자 : kdh
  Detail :
  *
  examples:
  *
  -->
<template>
  <c-select
    :stype="stype"
    :comboItems="plantItems"
    :editable="editable"
    :required="required"
    :disabled="disabled||roleAccess"
    :type="type"
    itemText="codeName"
    itemValue="code"
    :label="label"
    name="plantCd"
    v-model="plantCd"
    @datachange="val => {datachange(val)}"
  ></c-select>
</template>

<script>
import mixinCommon from './js/mixin-common'
export default {
  name: 'c-plant',
  mixins: [mixinCommon],
  props: {
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'edit',
    },
    stype: {
      type: String,
      default: 'select',
    },
    editable: {
      type: Boolean,
      default: true,
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    // 라벨 텍스트
    label: {
      type: String,
      default: '사업장', 
    },
    // 사용 여부
    disabled: {
      type: Boolean,
      default: false,
    },
    // 사용 여부
    width: {
      type: Number,
      default: 8,
    },
    // veeValidate 유효성 검사
    state: {
      type: Boolean,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    filter: {
      // 화학물질인경우 공통사업장을 포함, 나머지는 미포함으로 filter가 chm으로 들어오는 경우만 포함시킴.
      type: String,
      default: '',
    },
    reject: {
      // 제외 항목
      type: String,
      default: '',
    },
    // 본인사업장을 초기값으로 설정할지 여부
    isFirstValue: {
      type: Boolean,
      default: true,
    },
    // td에서 조작하는 사업장인지
    isTable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFrist: true,
      roleAccess: false,
      plantCd: null,
      plantName: '',
      allPlant: '',
      plantItems: [], // 사업장 리스트
    };
  },
  watch: {
    // TODO : 부모의 v-model 변경을 감시(예를 들면, db로부터 데이터를 조회 한 후 값을 바인딩 할 경우)
    value() {
      if (!this.isTable) {
        if (this.isFrist && this.isFirstValue && !this.value) {
          this.isFrist = false
          this.$emit('input', window.localStorage.getItem('defaultPlantCd'))
        } else {
          this.plantCd = this.value;
        }
  
        if (this.isFrist) {
          this.isFrist = false
        }
      } else {
        if (!this.value) {
          this.$emit('input', window.localStorage.getItem('defaultPlantCd'))
        } else {
          this.plantCd = this.value;
        }
      }
      // this.getList();
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {},
  beforeDestory() {},
  methods: {
    init() {
      if (this.value) {
        this.plantCd = this.value;
        this.$emit('input', this.value);
      } else {
        let defaultPlantCd = window.localStorage.getItem('defaultPlantCd');
        if (defaultPlantCd !== null) {
          this.$emit('input', defaultPlantCd);
        } else {
          this.$emit('input', this.$store.getters.user.plantCd);
        }
      }
      this.getList();
    },
    /**
     * 메뉴 tree 기초 정보 조회
     */
    getList() {
      this.$comm.getComboItems('PLANT_CD').then(_result => {
        this.plantItems = [];
        let plantCds;
        if (this.$store.getters.user.plantCds === null || this.$store.getters.user.plantCds === '') {
          plantCds = this.$store.getters.user.plantCd.split(',');
          this.allPlant = this.$store.getters.user.plantCd;
        } else {
          plantCds = this.$store.getters.user.plantCds.split(',');
          this.allPlant = this.$store.getters.user.plantCds;
        }
        this.$_.forEach(_result, _item => {
          let index = plantCds.findIndex(a => a === _item.code);
          if (index > -1) {
            this.plantItems.push(_item);
          }
        })
        if (this.reject !== undefined) {
          this.plantItems = this.$_.reject(this.plantItems, {
            code: this.reject,
          });
        }
      });
    },
    datachange(newValue) {
      if (newValue.value === null) {
        if (this.type !== 'edit') {
          newValue.value = this.allPlant + ',';
        }
      }
      // 데이터가 변경되는 경우 처리 해당 속성이 설정되어 있으면 조회처리하도록 수정
      this.$emit('input', newValue.value);
      this.$emit('datachange', newValue);
    },
  },
};
</script>
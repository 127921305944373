<!--
목적 : 확장검색 기능을 지원하는 컴포넌트
Detail :
 *
examples:
 *
-->
<template>
  <div class="fix-height">
    <q-input
      ref="ctext"
      filled
      bottom-slots
      :label="convertLabel"
      type="text"
      :counter="counter"
      :maxlength="maxlength"
      :dense="dense"
      stack-label
      class="customText"
      :class="[prefix ? 'prefix-custom-text' : '', beforeText ? 'before-text' : '', afterIcon ? 'customText-btn' : '']"
      :prefix="prefix"
      :suffix="suffix"
      :mask="mask"
      :placeholder="convertPlaceholder"
      fill-mask
      color="orange-7"
      :bg-color="type==='textarea' ? 'grey-1' : ''"
      :rules="!required ? null : [val => !!val || '']"
      :disable="disabled || !editable"
      :readonly="readonly"
      v-currency="numberOptions"
      v-model="vValue"
      @input="input">
      <template v-slot:label>
        <div v-if="label" class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{convertLabel}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>

      <template v-slot:before>
        <template v-if="beforeIcon && beforeIcon.length > 0">
          <q-icon
            v-for="(icon, index) in beforeIcon"
            :key="index"
            :name="icon.name"
            class="textOtherIcon"
            :class="{ 'cursor-pointer' : icon.click }"
            @click="iconClick(icon)">
              <q-tooltip v-if="icon.tooltip">{{icon.tooltip}}</q-tooltip>
          </q-icon>
        </template>
        <template v-if="beforeText">
          <div class="custom-text-before q-pl-xs q-pr-xs bg-blue-7 text-white">
            {{beforeText}}
          </div>
        </template>
      </template>

      <template v-slot:prepend v-if="prependIcon && prependIcon.length > 0">
        <q-icon
          v-for="(icon, index) in prependIcon"
          :key="index"
          :name="icon.name"
          class="textOtherIcon"
          :class="{ 'cursor-pointer' : icon.click }"
          @click="iconClick(icon)">
            <q-tooltip v-if="icon.tooltip">{{icon.tooltip}}</q-tooltip>
        </q-icon>
      </template>

      <template v-slot:append>
        <div v-if="detail && !disabled && editable" class="vendor-self cursor-pointer" @click="detailClick">상세</div>
        <q-icon v-if="search && !disabled && editable" name="search" @click="searchClick" class="cursor-pointer textCloseIcon"></q-icon>
        <q-icon v-if="close && !disabled && editable && !readonly" name="close" @click="closeClick" class="cursor-pointer textCloseIcon"></q-icon>
        <template v-if="appendIcon && appendIcon.length > 0">
          <q-icon
            v-for="(icon, index) in appendIcon"
            :key="index"
            :name="icon.name"
            class="textOtherIcon"
            :class="{ 'cursor-pointer' : icon.click }"
            @click="iconClick(icon)">
              <q-tooltip v-if="icon.tooltip">{{icon.tooltip}}</q-tooltip>
          </q-icon>
        </template>
      </template>

      <!-- <template v-slot:hint>
      </template> -->

      <template v-slot:after v-if="afterIcon && afterIcon.length > 0">
        <slot name="afterIconPre"></slot>
        <q-btn
          v-for="(icon, index) in afterIcon"
          :key="index"
          unelevated
          flat
          size="xs"
          class="textOtherIcon"
          :class="{ 'cursor-pointer' : icon.click }"
          :color="icon.color == 'teal' ? 'light-blue' : icon.color"
          :icon="icon.name"
          @click="iconClick(icon)"/>
        <slot name="afterIconAft"></slot>
      </template>
    </q-input>
  </div>
</template>

<script>
import { CurrencyDirective, setValue, getValue } from 'vue-currency-input'
import mixinCommon from './js/mixin-common'
export default {
  /* attributes: name, components, props, data */
  name: 'c-text-number',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: {
      type: [Number, String],
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    maxlength: {
      type: Number,
    },
    beforeIcon: {
      type: [String, Array],
    },
    prependIcon: {
      type: [String, Array],
    },
    appendIcon: {
      type: [String, Array],
    },
    afterIcon: {
      type: [String, Array],
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text', // text password textarea email search tel file number url time date
    },
    counter: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Boolean,
      default: true,
    },
    search: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    mask: {
      type: String,
      default: '',
    },
    beforeText: {
      type: String,
      default: '',
    },
    dense: {
      type: Boolean,
      default: true,
    },
    numberOptions: {
      type: Object,
      default: () => {
        return {
          currency: null,
          // hideNegligibleDecimalDigitsOnFocus: true,
          // precision: 0
        }
      }
    }
  },
  directives: {
    currency: CurrencyDirective
  },
  data() {
    return {
      vValue: '',
    };
  },
  computed: {
  },
  watch: {
    // todo : 부모 v-model 값이 변경되었을 경우 바인딩
    value() {
      this.setValue(this.value)
      // this.vValue = this.value;
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    // setTimeout(() => {
    //   this.setValue(this.value)
    //   // this.vValue = this.value;
    // }, 100);
  },
  mounted() {
  },
  beforeDestroy() {},
  destroyed() {},
  updated() {
  },
  /* methods */
  methods: {
    input() {
      // TODO : 부모에게 변경여부 전달
      let splitData = this.$_.split(this.vValue, '.');
      if (splitData && splitData.length > 1 && String(splitData[1]) == '00') {
        this.vValue = splitData[0]
      }
      setTimeout(() => {
        this.$emit('input', getValue(this.$refs.ctext));
        this.$emit('dataChange', getValue(this.$refs.ctext));
      }, 100);
    },
    setValue(value) {
      setValue(this.$refs.ctext, value)
    },
    /**
     * iconClick시 부모에게 emit한다.
     */
    iconClick(_item) {
      // callbackname -> name 수정
      // 위에 주석 왜 수정한건지?
      if (!_item.callbackName) {
        return ;
      }
      // this.$emit(_item.callbackName, _item);
      this.$emit('callback', _item);
    },
    /**
     * 입력값 초기화
     */
    closeClick() {
      this.vValue = '';
      this.$emit('input', this.vValue);
      this.$emit('dataChange', this.vValue);
    },
    searchClick() {
      this.$emit('searchPop', this.vValue)
    },
    detailClick() {
      this.$emit('detailPop', this.vValue)
    },
    focus() {
      setTimeout(() => {
        this.$refs['ctext'].focus();
        this.$refs['ctext'].select();
      }, 100);
    },
  },
};
</script>
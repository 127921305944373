import request from '@/utils/request';
import selectConfig from '@/js/selectConfig';
import selectPartnerConfig from '@/js/selectPartnerConfig';
import transactionPartnerConfig from '@/js/transactionPartnerConfig';
import common from '@/js/common';

export function sso(_loginId) {
      const data = {
    loginId: _loginId,
  };
  return request({
    url: transactionPartnerConfig.sso.insert.url,
    method: 'post',
    data
  });
}

export function loginByUserAccount(_userId, _userPwd, _userOtpPass) {
  const data = {
    loginId: _userId,
    loginPwd: _userPwd,
    userOtpPass: _userOtpPass,
    deviceType: 'V',
  };
  return request({
    url: transactionPartnerConfig.auth.login.insert.url,
    method: 'post',
    data,
  });
}

export function logout() {
  return request({
    url: transactionPartnerConfig.auth.login.logout.url,
    method: 'post',
  });
}

export function getUserInfo(token) {
  return request({
    url: selectPartnerConfig.auth.userInfo.url,
    method: 'get',
    params: {
      token,
    },
  });
}

export function updateTokenByRefreshToken() {
  return request({
    url: selectPartnerConfig.auth.login.refresh.url,
    method: 'get',
  });
}

export function validToken() {
  return request({
    url: selectPartnerConfig.auth.login.valid.url,
    method: 'get',
  });
}

export function getLangInfo(_lang, _divide) {
  const data = {
    lang: _lang,
    divide: _divide,
  };
  return request({
    url: selectConfig.system.lang.url,
    method: 'post',
    data,
  });
}

export function getUserMenus() {
  var url = selectConfig.sys.userMenu.get.url + 'VENDOR';
  return request({
    url: url,
    method: 'get',
    params: {
      lang: common.getLang(),
      deviceTypeCd: 'V',
    }
  });
}

export function getFavorites(userId) {
  var url = selectConfig.sys.favorite.list.url;
  return request({
    url: url,
    method: 'get',
    params: {
      userId: userId
    }
  });
}
<!--
목적 : 확장검색 기능을 지원하는 컴포넌트
Detail :
 *
examples:
 *
-->
<template>
  <div>
    <q-dialog
      ref="dialog"
      persistent
      @show="onShow" 
      @hide="onHide"
      no-esc-dismiss
      no-backdrop-dismiss
      :content-class="{ customDialog: true, 'full-dialog': param.isFull, 'mini-dialog': !param.isFull }"
      :content-style="{ 'opacity' : bodyOpacity }"
      position="top"
      v-model="param.visible">
      <q-layout
        ref="dialog-layout"
        view="Lhh lpR fff"
        class="bg-white no-scroll dialog-layout"
        :style="{ 'max-width': param.isFull ? 'none' : param.width, 'min-height': 'auto', 'max-height': param.isFull ? '98%' : 'calc(100vh - 60px)' }">
        <!-- <q-header ref="dialog-header" class="text-white" :class="param.isFull ? (param.isApproval ? 'bg-orange-7' : 'bg-blue-6') : 'bg-orange-7'"> -->
        <q-header ref="dialog-header" class="text-white dialog-header" :class="param.isFull ? (param.isApproval ? 'bg-mini-dialog cursor-move' : 'bg-full-dialog') : 'bg-mini-dialog cursor-move'">
          <q-toolbar ref="toolbar-header">
            <q-toolbar-title>{{$comm.getLangLabel(param.title)}}</q-toolbar-title>
            <!-- <q-btn flat @click="subMenuOpen = !subMenuOpen" round dense icon="menu">
              <q-tooltip class="bg-white text-primary">기타메뉴</q-tooltip>
            </q-btn> -->
            <!-- param.isFull: {{param.isFull}} -->
            <q-btn flat @click="closePopup" round dense icon="close">
              <q-tooltip class="bg-white text-primary">창닫기</q-tooltip>
            </q-btn>
          </q-toolbar>
        </q-header>

        <!-- <q-footer class="bg-black text-white">
          <q-toolbar inset>
            <q-toolbar-title>Footer</q-toolbar-title>
          </q-toolbar>
        </q-footer> -->

        <q-drawer side="right" bordered v-model="subMenuOpen" :width="200" :breakpoint="300" content-class="bg-grey-3 q-pa-sm">
          <div v-for="n in 50" :key="n">Drawer {{ n }} / 50</div>
        </q-drawer>

        <q-page-container class="custom-dialog-page-container"
          :style="{ 'overflow-y': 'auto', 'min-height': 'auto', 'max-height': (param.isFull ? 'calc(100vh)' : 'calc(100vh - 100px)') }">
          <q-page padding style="min-height:200px;">
            <div :style="{ 'height': param.isFull ? 'calc(100vh - 70px)' : 'auto', }">
              <component
                :is="param.target"
                :style="`background:` + bodyColor"
                :popupParam="param.param"
                :contentHeight="contentHeight"
                :returnData="returnData"
                @closePopup="param.closeCallback"
              />
            </div>
          </q-page>
        </q-page-container>
      </q-layout>
    </q-dialog>
  </div>
</template>

<script>
var isChrome = /*@cc_on!@*/false || !document.documentMode
import mixinCommon from './js/mixin-common'
export default {
  /* attributes: name, components, props, data */
  name: 'c-dialog',
  mixins: [mixinCommon],
  props: {
    param: {
      type: Object,
      default: () => ({
        isFull: true,
        isApproval: false,
        target: null,
        title: '',
        visible: false,
        width: '',
        height: null,
        top: '',
        param: {},
        closeCallback: null,
      }),
    },
  },
  data() {
    return {
      bodyColor: '#FFFFFF',
      bodyOpacity: '0.99 !important',
      bodyTimer: '',
      subMenuOpen: false,
      maximizedToggle: true,
      contentHeight: 'auto',
      returnData: { // 최대 3개의 data를 return
        col1: null,
        col2: null,
        col3: null,
      },
      target: null,
      nodeDragg: null
    };
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
    if (this.param.visible && this.bodyTimer) {
      clearTimeout(this.bodyTimer)
    }
    if (!isChrome) {
      this.bodyOpacity = '0.99 !important';
    }
    window.removeEventListener('resize', this.setSize);
  },
  destroyed() {},
  updated() {
    if (this.param.visible) {
      if (!isChrome) {
        this.bodyTimer = setTimeout(this.setBodyTimer,2000)
      } else {
        this.bodyOpacity = '1 !important';
      }
    } else {
      if (!isChrome) {
        this.bodyOpacity = '0.99 !important';
      }
    }
  },
  watch: {
    'param.visible'() {
      this.setSize();
    }
  },
  /* methods */
  methods: {
    init() {
      // this.contentHeight = this.$refs['dialog-layout'].off
      // this.setBodyColor();
      // window.getApp.$on('mainColorChanged', (_color) => {
      //   this.bodyColor = _color;
      // });
    },
    setBodyTimer() {
      this.bodyOpacity = '1 !important';
      clearTimeout(this.bodyTimer)
    },
    // setBodyColor() {
    //   this.$nextTick(() => {
    //     let localStorageColor = window.localStorage.getItem('bodyColor');
    //     this.bodyColor = localStorageColor || this.bodyColor;
    //   });
    // }
    setSize() {
      if (this.param.visible) {
        setTimeout(() => {
          let height = this.$refs['dialog-layout'].$el.offsetHeight - this.$refs['dialog-header'].$el.offsetHeight;
          this.contentHeight = !isNaN(height) ? height : 'auto';
        }, 300);
      } else {
        this.contentHeight = 'auto'
      }
    },
    closePopup() {
      this.param.closeCallback(this.returnData)
    },
    onShow() {
      if(!this.param.isFull) {
        let dialogElements = document.getElementsByClassName("mini-dialog");
        this.target = dialogElements[dialogElements.length - 1].querySelector(".dialog-layout");
        this.nodeDragg = this.target.querySelector(".q-header");
        this.nodeDragg.addEventListener("mousedown", this.onGrab);

        this.$emit("onShow");
      }
    },
    onHide() {
      if(!this.param.isFull) {
        document.removeEventListener("mousemove", this.onDrag);
        document.removeEventListener("mouseup", this.onLetGo);
        this.nodeDragg.removeEventListener("mousedown", this.onGrab);
        this.$emit("onHide");
      }
    },
    onDrag(e) {
      if(!this.param.isFull) {
        let originalStyles = window.getComputedStyle(this.target);
        this.target.style.left =
          parseInt(originalStyles.left) + e.movementX + "px";
        this.target.style.top = parseInt(originalStyles.top) + e.movementY + "px";

        if (parseInt(originalStyles.top) + e.movementY < 0) {
          this.target.style.top = "0px"
        }
        if (parseInt(originalStyles.top) + e.movementY + 60 > window.innerHeight) {
          this.target.style.top = (window.innerHeight - 60) + "px"
        }
      }
    },
    onLetGo() {
      if(!this.param.isFull) {
        document.removeEventListener("mousemove", this.onDrag);
        document.removeEventListener("mouseup", this.onLetGo);
      }
    },
    onGrab() {
      if(!this.param.isFull) {
        document.addEventListener("mousemove", this.onDrag);
        document.addEventListener("mouseup", this.onLetGo);
      }
    }
  },
};
</script>
<style lang="sass">
.q-dialog__inner--minimized
  padding: 0px !important
.q-toolbar
  min-height: 42px
  .q-toolbar__title
    font-size: 1.2em
    font-weight: 700
    text-align: center

.full-dialog
  .q-dialog__inner
    height: 100%
</style>
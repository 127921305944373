<!--
  TODO : 버튼 공통 모듈
-->
<template>
  <q-btn-group v-show="btnEditable" outline style="margin-right:2px !important">
    <c-btn 
      v-show="!data.flag"
      color="blue-7"
      :label="`${data.title} 정보 수정`" 
      icon="update" 
      @btnClicked="updateMode" />
    <c-btn 
      v-show="data.flag"
      label="돌아가기" 
      icon="undo" 
      @btnClicked="back" />
  </q-btn-group>
</template>

<script>
import mixinCommon from './js/mixin-common'
export default {
  /* attributes: name, components, props, data */
  name: 'c-update-btn',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: function() {
        return {
          title: '',
          flag: false,
        }
      },
    },
    btnEditable: {
      type: Boolean,
      default: false,
    },
    flagCondition: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  watch: {
    btnEditable() {
      this.setFlag();
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  mounted() {
    this.setFlag();
  },
  /* methods */
  methods: {
    /**
     * 수정모드
     */
    updateMode() {
      this.$set(this.data, 'flag', true)
      this.$emit('updateMode')
    },
    /**
     * 돌아가기
     */
    back() {
      this.$emit('back')
      this.$set(this.data, 'flag', false)
    },
    setFlag() {
      if (this.flagCondition) {
        // 현재 단계에서 수정가능한 상태가 되도록 처리
        this.data.flag = !this.btnEditable ? true : false
      }
    }
  },
};
</script>


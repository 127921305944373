<template>
  <div :class="headerbg" class="app-header header-shadow">
    <div class="logo-src anivertilogo" />
    <div class="app-header__content">
      <div class="app-header-left">
        <div class="top-left-hamburger-left">
        </div>
        <!-- <div class="header__pane ml-auto menucollapse">
          <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
            v-bind:class="{ 'is-active' : !isOpen }" @click="toggleBodyClass('closed-sidebar')">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div> -->
        <TabMenus />
        <!-- <SearchBox /> -->
        <!-- <MegaMenu /> -->
      </div>
      <div class="app-header-right">
        <!-- <ShortCut /> -->
        <ShortTabMenus />
        <HeaderZoom />
        <!-- <HeaderDots /> -->
        <UserArea />
        <Favorites />
      </div>
    </div>
    <!-- <div class="app-header__mobile-menu">
      <div>
        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
          v-bind:class="{ 'is-active' : isOpen }" @click="toggleMobile('closed-sidebar-open')">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div> -->
    <div class="app-header__menu">
      <q-icon name="home" @click="goMain" class="mobile-home"></q-icon>
    </div>
  </div>
</template>

<script>
// import SearchBox from "./Header/SearchBox";
// import MegaMenu from "./Header/MegaMenu";
import TabMenus from "./Header/TabMenus";
import ShortTabMenus from "./Header/TabShortMenus";
// import HeaderDots from "./Header/HeaderDots";
import UserArea from "./Header/HeaderUserArea";
import Favorites from "./Header/HeaderFavorite";
import HeaderZoom from "./Header/HeaderZoom";
// import ShortCut from "./Header/ShortCut";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faEllipsisV);
export default {
  name: "Header",
  components: {
    TabMenus,
    ShortTabMenus,
    // SearchBox,
    // MegaMenu,
    // HeaderDots,
    UserArea,
    Favorites,
    HeaderZoom,
    // ShortCut,
    // "font-awesome-icon": FontAwesomeIcon
  },
  data() {
    return {
      isOpen: false,
      isOpenMobileMenu: false,
    };
  },
  props: {
    headerbg: String
  },
  methods: {
    toggleBodyClass(className) {
      const el = document.body;
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    toggleMobile(className) {
      const el = document.body;
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    toggleMobile2(className) {
      const el = document.body;
      this.isOpenMobileMenu = !this.isOpenMobileMenu;

      if (this.isOpenMobileMenu) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    goMain() {
      this.$router.push('/main/index');
    }
  }
};
</script>
<style lang="sass">
.menucollapse .hamburger-inner, .menucollapse .hamburger-inner::before, .menucollapse .hamburger-inner::after
  background: #fff !important
</style>

import { render, staticRenderFns } from "./CPickFile.vue?vue&type=template&id=59884a80"
import script from "./CPickFile.vue?vue&type=script&lang=js"
export * from "./CPickFile.vue?vue&type=script&lang=js"
import style0 from "./CPickFile.vue?vue&type=style&index=0&id=59884a80&lang=sass"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\NewDevelopment\\SHMS\\FrontEnd\\PsmPartner\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('59884a80')) {
      api.createRecord('59884a80', component.options)
    } else {
      api.reload('59884a80', component.options)
    }
    module.hot.accept("./CPickFile.vue?vue&type=template&id=59884a80", function () {
      api.rerender('59884a80', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/CPickFile.vue"
export default component.exports
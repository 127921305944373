var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-header header-shadow", class: _vm.headerbg },
    [
      _c("div", { staticClass: "logo-src anivertilogo" }),
      _c("div", { staticClass: "app-header__content" }, [
        _c(
          "div",
          { staticClass: "app-header-left" },
          [
            _c("div", { staticClass: "top-left-hamburger-left" }),
            _c("TabMenus"),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "app-header-right" },
          [
            _c("ShortTabMenus"),
            _c("HeaderZoom"),
            _c("UserArea"),
            _c("Favorites"),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "app-header__menu" },
        [
          _c("q-icon", {
            staticClass: "mobile-home",
            attrs: { name: "home" },
            on: { click: _vm.goMain },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
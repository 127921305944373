<!--
목적 : 화학자재 컴포넌트
-->
<template>
  <div class="fix-height">
    <q-field
      ref="customChemMaterial"
      class="customChemMaterial"
      :dense="dense"
      stack-label
      filled
      color="orange-7"
      :label="convertLabel"
      :disable="disabled||!editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="valueText"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{convertLabel}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        {{valueText}}
      </template>

      <template v-slot:append>
        <div class="vendor-self cursor-pointer" @click="detailPop">상세</div>
        <q-icon v-if="!disabled&&editable" name="search" @click="search" class="cursor-pointer"></q-icon>
        <q-icon v-if="!disabled&&editable" name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-field>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import mixinCommon from './js/mixin-common'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'c-chem-material',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
      default: 'field',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: { // 사용자의 id를 받아 처리
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '화학자재',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'edit', // edit 쓰기 search 읽기
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    plantCd: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      vValue: null,
      valueText: '',
      chemMaterial: {
        plantCd: null,  // 사업장코드
        mdmChemMaterialId: '',  // 화학자재 일련번호
        materialCd: '',  // 자재코드
        deliveryVendorCd: '',  // 납품업체 코드
        mfgVendorCd: '',  // 제조업체 코드
        psmFlag: 'N',  // PSM 대상 여부
        propertiesStateCd: null,  // 성상-공통코드
        fugacity: '',  // 비산성(고체)
        boilPoint: '',  // 끊는점(액체,기체)
        meltingPoint: '',  // 녹는점
        moleWeight: '',  // 분자량
        usageCd: null,  // 용도-회사별 공통코드
        usageEtc: '',  // 용도 기타
        hsNo: '',  // HS번호
        specificGravity: '',  // 비중
        casNo: '',  // CAS No.
        serialNo: '',  // 고유번호(유독물번호or고시번호)
        keNo: '',  // KE 번호
        storagePlace: '',  // 보관장소
        productCategoryCd: null,  // 제품구분
        mixFlag: 'N',  // 물질구성(단일 N/혼합 Y)
        licensingFlag: 'N',  // 인허가대상 물질 여부 Y/N
        dangerFlag: 'N',  // 위험물 여부Y/N
        makeFlag: 'N',  // 제품분류(제조)
        impFlag: 'N',  // 제품분류(수입)
        buyFlag: 'N',  // 제품분류(구매)
        usingFlag: 'N',  // 제품분류(사용)
        expFlag: 'N',  // 제품분류(수출)
        salesFlag: 'N',  // 제품분류(판매)
        toxicPoisonFlag: 'N',  // 유해화학물질포함여부(유독물질)
        toxicPermitFlag: 'N',  // 유해화학물질포함여부(허가물질)
        toxicLimitFlag: 'N',  // 유해화학물질포함여부(제한물질)
        toxicProhibitFlag: 'N',  // 유해화학물질포함여부(금지물질)
        remarks: '',  // 비고
        useFlag: 'Y',  // 사용여부
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '60%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
  },
  watch: {
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
        this.$set(this.$data, 'vValue', this.value)
        // this.vValue = this.value;
        this.setValueText();
      },
      deep: true,
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    if (this.value) {
      this.$set(this.$data, 'vValue', this.value)
    }
    this.setValueText();
  },
  mounted() {
    if (!this.disabled) {
      this.$refs['customChemMaterial'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  beforeDestroy() {
    if (!this.disabled) {
      this.$refs['customChemMaterial'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  /* methods */
  methods: {
    /**
     * v-model로 받은 값을 통해 사용자 정보 조회
     *
     * type에 따라 valueText에 표시되는 값 변경
     */
    setValueText() {
      if (this.vValue) {
        this.$http.url = this.$format(selectConfig.mdm.mam.chem.get.url, this.vValue);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.valueText = _result.data.materialName;
          this.$_.extend(this.chemMaterial, _result.data)
          this.$emit('chemMaterialInfo', this.chemMaterial);
        },);
      } else {
        this.valueText = '';
        Object.assign(this.$data.chemMaterial, this.$options.data().chemMaterial);
      }
    },
    search() {
      this.popupOptions.title = '화학자재 검색';
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/mam/chemPop.vue'}`);
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.valueText = data[0].materialName;
        this.$emit('dataChange', data[0]);
        this.$emit('input', data[0].mdmChemMaterialId);
      }
    },
    reset() {
      this.valueText = '';
      this.$emit('input', null);
      this.$emit('dataChange', null);
      this.$emit('chemMaterialInfo', null);
    },
    detailPop() {
      if (this.chemMaterial.mdmChemMaterialId) {
        this.popupOptions.title = '화학자재 상세'; // 화학자재 상세
        this.popupOptions.param = {
          mdmChemMaterialId: this.chemMaterial.mdmChemMaterialId,
          plantCd: this.chemMaterial.plantCd,
        };
        this.popupOptions.target = () => import(`${'@/pages/mdm/mam/chemDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeFacilityDetailPopup;
      }
    },
    closeFacilityDetailPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  },
};
</script>
<style lang="sass">
.customChemMaterial
  .q-field__control-container
    padding-bottom: 2px !important
  padding-bottom: 14px !important
  .q-field__native.row
    padding-left: 3px
    font-size: 0.95em
    padding-top: 4px !important
  .q-field__native
    padding-bottom: 0px !important
    cursor: pointer
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8rem
  .dateCloseIcon
    font-size: 0.8rem !important
    display: none
.customChemMaterial:hover
  .dateCloseIcon
    display: block
.customChemMaterial.q-field--dense .q-field__control, .customChemMaterial.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 31px !important
  height: min-content !important
.customChemMaterial.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px
.customchipdept.q-chip
  margin-top: -3px !important
  margin-right: 3px !important
  .q-chip__content
    font-size: 0.95em !important
</style>
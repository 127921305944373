/**
 * 조회용 협력사 전용 config
 */
let selectPartnerConfig = {
  auth: {
    login: {
      refresh: {
        url: "/api/auth/partner/refresh"
      },
      valid: {
        url: "/api/auth/partner/valid"
      }
    },
    userInfo: {
      url: "/api/auth/partner/user/info",
    },
  },
};

export default selectPartnerConfig;
